<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>精品评价列表</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--搜索-->
            <div v-show="issearch" class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="用户昵称">
                                <el-input v-model="search.user_nick"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="手机号">
                                <el-input v-model="search.phone"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="审批状态">
                                <el-select v-model="search.examine_state" placeholder="请选择状态" style="width: 100%">
                                    <el-option label="全部" value=""></el-option>
                                    <el-option label="未审批" :value=0></el-option>
                                    <el-option label="通过" :value=1></el-option>
                                    <el-option label="不通过" :value=2></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="课程名称">
                                <el-input v-model="search.lesson_name"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="6">
                            <el-form-item label="课程评星">
                                <el-select v-model="search.lesson_rate" placeholder="请选择评星" style="width: 100%">
                                    <el-option label="全部" value=""></el-option>
                                    <el-option label="1星" :value=1></el-option>
                                    <el-option label="2星" :value=2></el-option>
                                    <el-option label="3星" :value=3></el-option>
                                    <el-option label="4星" :value=4></el-option>
                                    <el-option label="5星" :value=5></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="门店评星">
                                <el-select v-model="search.store_rate" placeholder="请选择评星" style="width: 100%">
                                    <el-option label="全部" value=""></el-option>
                                    <el-option label="1星" :value=1></el-option>
                                    <el-option label="2星" :value=2></el-option>
                                    <el-option label="3星" :value=3></el-option>
                                    <el-option label="4星" :value=4></el-option>
                                    <el-option label="5星" :value=5></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="教练评星">
                                <el-select v-model="search.coach_rate" placeholder="请选择评星" style="width: 100%">
                                    <el-option label="全部" value=""></el-option>
                                    <el-option label="1星" :value=1></el-option>
                                    <el-option label="2星" :value=2></el-option>
                                    <el-option label="3星" :value=3></el-option>
                                    <el-option label="4星" :value=4></el-option>
                                    <el-option label="5星" :value=5></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="门店名称">
                                <el-input v-model="search.store_name"></el-input>
                            </el-form-item>
                        </el-col>

                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12"><h3>{{ page_name }}</h3></el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                                   @click="issearch = !issearch">隐藏搜索
                        </el-button>
                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                                   @click="issearch = !issearch">显示搜索
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table
                    size="medium"
                    border
                    v-loading="loading"
                    :data="tableData"
                    style="width: 100%">
                <el-table-column
                        prop="company_name"
                        label="所属公司">
                </el-table-column>
                <el-table-column
                        prop="user_nick"
                        label="用户昵称">
                </el-table-column>
                <el-table-column
                        prop="phone"
                        label="手机号">
                </el-table-column>
                <el-table-column
                        prop="anonymous"
                        label="是否匿名">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.anonymous===1" type="info">是</el-tag>
                        <el-tag size="mini" v-if="scope.row.anonymous===0" type="info">否</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="create_time"
                        label="评价时间">
                </el-table-column>
                <el-table-column
                        prop="type"
                        label="类型">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.type===1">团课</el-tag>
                        <el-tag size="mini" v-if="scope.row.type===2">训练营</el-tag>
                        <el-tag size="mini" v-if="scope.row.type===3">私教</el-tag>
                        <el-tag size="mini" v-if="scope.row.type===0" type="info">其他</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="examine_state"
                        label="审批状态"
                        width="180">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.examine_state===0" type="info">未审核</el-tag>
                        <el-tag size="mini" v-if="scope.row.examine_state===1" type="success">
                            展示{{ scope.row.examine_time }}
                        </el-tag>
                        <el-tag size="mini" v-if="scope.row.examine_state===2" type="danger">不展示</el-tag>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="lesson_rate"
                        label="课程评价"
                        width="200">
                    <template slot-scope="scope">
                        <el-popover
                                placement="bottom"
                                width="200"
                                trigger="click"
                                :content="scope.row.lesson_comment">
                            <span slot="reference">
                                <span>{{ scope.row.lesson_rate }}星:</span>
                                <span>{{ scope.row.lesson_name }}:</span>
                                <span>{{ scope.row.lesson_comment }}:</span>
                            </span>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="store_rate"
                        label="门店评价"
                        width="200">
                    <template slot-scope="scope">
                        <el-popover
                                placement="bottom"
                                width="200"
                                trigger="click"
                                :content="scope.row.store_comment">
                            <span slot="reference">
                                <span>{{ scope.row.store_rate }}星:</span>
                                <span>{{ scope.row.store_name }}:</span>
                                <span>{{ scope.row.store_comment }}:</span>
                            </span>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="coach_rate"
                        label="教练评价"
                        width="200">
                    <template slot-scope="scope">
                        <el-popover
                                placement="bottom"
                                width="200"
                                trigger="click"
                                :content="scope.row.coach_comment">
                            <span slot="reference">
                                <span>{{ scope.row.coach_rate }}星:</span>
                                <span>{{ scope.row.coach_name }}:</span>
                                <span>{{ scope.row.coach_comment }}:</span>
                            </span>
                        </el-popover>
                    </template>
                </el-table-column>


                <el-table-column
                        prop="cover"
                        label="评价照片"
                        width="100">
                    <template slot-scope="scope">
                        <div v-if="!is_empty(scope.row.comment_photo)">
                            <el-image
                                    v-for="(item,index) in scope.row.comment_photo" :key="index"
                                    style="width: 22px; height: 22px; margin-right: 4px;"
                                    :src="item"
                                    fit="contain"
                                    :preview-src-list="scope.row.comment_photo">
                            </el-image>
                        </div>
                    </template>
                </el-table-column>


                <el-table-column
                        fixed="right"
                        label="操作"
                        width="240">
                    <template slot-scope="scope">
                        <el-button
                                v-if="is_auth('comment.comment.removewell') "
                                @click="operation_tip(scope.row.comment_uuid,scope.row.user_nick,'removewell')"
                                type="danger" size="mini">移除精选
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination
                    @current-change="getlist"
                    :page-size="this.env.pageSize"
                    :pager-count="7"
                    background
                    layout="prev, pager, next, total"
                    :current-page.sync="page"
                    :total="count">
            </el-pagination>

        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            page_name: '精品评价列表',
            loading: true,      // 加载状态
            issearch: false,    // 搜索是否展示
            search: {},         // 搜索内容
            count: 0,           // 数据总条数
            page: 1,            // 当前页数
            tableData: [],// 列表内容
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 判断是否为空
        is_empty(auth) {
            return this.Tool.is_empty(auth)
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.search = {
                coupon_bale_title: '',
                publish_state: '',
                state: '',
            }
            this.searchtime = null
            this.is_search();
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "comment.comment.getlistwell",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 操作提示
        operation_tip(comment_uuid = '', user_nick = '', examine = '') {

            let tip = ''
            let options = {}
            // 启用
            if (examine == 'removewell') {
                tip = '将【' + user_nick + '】的评价移除精选'
            }

            // 弹出二次确认
            this.$confirm(tip, '确认信息', options)
                    .then(() => {
                        this.isoperation(comment_uuid, examine)
                    })
                    .catch(() => {
                    });
        },
        // 操作
        isoperation(comment_uuid = '', examine = '') {
            let postdata = {
                api_name: "comment.comment.removewell",
                token: this.Tool.get_l_cache('token'),
                comment_uuid,
                examine,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.getlist()    // 刷新列表(重新搜索)
                        }
                    });
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

/*表格不换行*/
>>> .el-table__body .cell {
    white-space: nowrap;
    /*overflow: initial;*/
}

.form-tag_colour-div {
    min-height: 200px;
}

</style>
